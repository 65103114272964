body {
  --black: #191414;
  --dark: #212121;
  --light: #535353;
  --lighter: #b3b3b3;
  --accent: white;
  --main: #1db954;
  --secondary: transparent;
  --red: #bb3f3f;

  background-color: var(--black);
  color: var(--accent);
  font-family: sofia-pro, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

* {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: var(--accent);
}

h1,
h2,
h3,
h4 {
  text-align: center;
  font-weight: 600;
  font-style: normal;
}

p {
  margin: 0;
  font-size: calc(1em + 0.390625vw);
}

h1 {
  font-size: calc(2em + 0.390625vw);
}

h2 {
  font-size: calc(1.5em + 0.390625vw);
}

h3 {
  font-size: calc(1.2em + 0.390625vw);
}

h4 {
  font-size: calc(1em + 0.390625vw);
}

div {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

.delay-entrance {
  opacity: 0;
  animation-fill-mode: forwards;
}
